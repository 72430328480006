<template>
  <div id="main-content">

    <div class="banner">
      <img :src="blog" alt="" srcset="" class="blog blog-img">
      <img :src="blog_desc" alt="" srcset="" class="blog_desc blog-img">
      <img :src="blog_lines" alt="" srcset="" class="blog_lines blog-img">
    </div>

    <div class="news">
      <div class="title">Research</div>

      <div class="content">
        <div
            class="news-item"
            v-for="news of researchList"
            :key="news.id"
            @click="openNew(news)"
        >
          <div class="pic">
            <img :src="imageBaseUrl + news.thumbnail" alt="" srcset=""/>
          </div>

          <div class="desc">
            <div class="top">
              <div class="desc-title">
                {{ news.title }}
              </div>

              <div class="new-info">
                <div class="info-author">
                  <span class="by">BY </span>
                  <span class="author">{{ news.author }}</span>
                </div>

                <div class="info-datetime">
                  <span>
                      <img
                          :src="time"
                          alt=""
                          class="time"
                      />
                      {{ news.datetime }}
                  </span>
                </div>
              </div>
            </div>

            <div class="abstract">
              <span>
                {{ news.abstract }}
              </span>
            </div>
          </div>
        </div>
        <img
            :src="uArrow"
            alt=""
            class="up-arrow"
            @click="changeResearchPage('add')"
            v-if="research_page.total > research_page.page * research_page.size"
        />
        <img
            :src="dArrow"
            alt=""
            class="down-arrow"
            @click="changeResearchPage('min')"
            v-if="research_page.page > 1"
        />
      </div>
    </div>

    <div class="news">
      <div class="title">In the news</div>

      <div class="content">
        <div
            class="news-item"
            v-for="news of newList"
            :key="news.id"
            @click="openNew(news)"
        >
          <div class="pic">
            <img :src="imageBaseUrl + news.thumbnail" alt="" srcset=""/>
          </div>

          <div class="desc">
            <div class="top">
              <div class="desc-title">
                {{ news.title }}
              </div>

              <div class="new-info">
                <div class="info-author">
                  <span class="by">BY </span>
                  <span class="author">{{ news.author }}</span>
                </div>

                <div class="info-datetime">
                  <span>
                      <img
                          :src="time"
                          alt=""
                          class="time"
                      />
                      {{ news.datetime }}
                  </span>
                </div>
              </div>
            </div>

            <div class="abstract">
              <span>
                {{ news.abstract }}
              </span>
            </div>
          </div>
        </div>
        <img
            :src="uArrow"
            alt=""
            class="up-arrow"
            @click="changePage('add')"
            v-if="page.total > page.page * page.size"
        />
        <img
            :src="dArrow"
            alt=""
            class="down-arrow"
            @click="changePage('min')"
            v-if="page.page > 1"
        />
      </div>
    </div>

    <div class="footer">
      Copyright © 2021 ArkStream | Powered by BlockArk
    </div>
  </div>
</template>

<script>
import '../type/index.css'
import { onMounted, reactive, toRefs } from "vue";
import axios from "axios";
import linked from "../assets/Linkedin.png";
import shenglue from "../assets/shenglue.png";
import time from "../assets/time.png";
import arrow from "../assets/arrow.png";
import uArrow from "../assets/right-arrow.png";
import dArrow from "../assets/left-arrow.png";
import sousuo from "../assets/logo.png";
import medium from "../assets/02.png";
import blog from "../assets/blog.png";
import blog_desc from "../assets/blog_desc.png";
import blog_lines from "../assets/blog_lines.png";

const baseURL = location.hostname === "localhost" ? "/api" : "/";
const ajax = axios.create({
  baseURL,
});
export default {
  name: "App",

  setup() {
    const data = reactive({
      newList: [],
      page: {
        page: 1,
        size: 3,
        total: 0,
      },
      researchList: [],
      research_page: {
        page: 1,
        size: 3,
        total: 0,
      },
      // imageBaseUrl: "http://150.109.75.31/",
      imageBaseUrl: location.hostname === "localhost" ? "/api" : "/",
      weCanDo: [],
      teammates: [],
      linked,
      time,
      arrow,
      uArrow,
      dArrow,
      sousuo,
      medium,
      shenglue,
      blog,
      blog_desc,
      blog_lines,
      clients: [],
    });

    const getNews = () => {
      ajax
          .get("/list_news/", {
            params: {
              page: data.page.page,
              pagesize: data.page.size,
            },
          })
          .then((res) => {
            if (res.data.code === 200) {
              data.newList = res.data.data.list;
              data.page.total = res.data.data.count;
            }
          });
    };

    const getResearch = () => {
      ajax
          .get("/list_research/", {
            params: {
              page: data.research_page.page,
              pagesize: data.research_page.size,
            },
          })
          .then((res) => {
            if (res.data.code === 200) {
              data.researchList = res.data.data.list;
              data.research_page.total = res.data.data.count;
            }
          });
    };

    const goLink = (team) => {
      window.open(team.link);
    };

    const openNew = (news) => {
      window.open(news.link);
    };

    const goClient = (client) => {
      window.open(client.link);
    };

    const mouseOverClient = ($event) => {
      $event.currentTarget.className = "img-box mouse-over-client";
    };

    const mouseOutClient = ($event) => {
      $event.currentTarget.className = "img-box";
    };

    const changePage = (type) => {
      if (type === "add" && data.page.total > data.page.page * data.page.size) {
        data.page.page++;
      } else {
        data.page.page--;
      }
      console.log(data.page.page);
      getNews();
    };

    const changeResearchPage = (type) => {
      if (type === "add" && data.research_page.total > data.research_page.page * data.research_page.size) {
        data.research_page.page++;
      } else {
        data.research_page.page--;
      }
      console.log(data.research_page.page);
      getResearch();
    };
    onMounted(() => {
      getNews();
      getResearch();
          document.documentElement.scrollTop = document.body.scrollTop = 0
    });

    return {
      ...toRefs(data),
      goLink,
      openNew,
      goClient,
      changePage,
      changeResearchPage,
      mouseOverClient,
      mouseOutClient,
    };
  },

};
</script>


<style lang="scss" scoped>

#main-content {
  background-color: #0c0c0c;
  min-height: 100vh;
  max-width: 100vw;
  padding-bottom: .536rem /* pxToRem */
;


  .banner {
    margin: 0 auto;
    text-align: center;;

    .blog-img {
      display: block;
      margin: 0 auto;
      margin-bottom: .1rem;
    }

    .blog {
      width: .45rem;
      padding-top: .39rem;
    }

    .blog_desc {
      width: 1.05rem;
    }

    .blog_lines {
      width: 1.8rem;
    }
  }


  .news {
    width: 3.04rem /* pxToRem */
    /* pxToRem */
  ;
    /*height: 2rem;*/
    margin: 0 auto;
    margin-top: 0.826171875rem /* pxToRem */
  ;
    font-size: 0.1171875rem;

    .content {
      position: relative;

      .up-arrow,
      .down-arrow {
        position: absolute;
        left: 0.05rem;
        top: 100%;
        width: 0.1rem;
        cursor: pointer;
        z-index: 10;
      }

      .up-arrow {
        left: 0.16rem;
      }
    }


    .title {
      font-family: 'KABELM';
      font-size: .12rem;
      height: .12rem;
      line-height: .12rem;
      color: #1f47b3;
      margin-bottom: 0.16796875rem;
      text-align: right;;
    }

    .news-item {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      display: -moz-flex;
      display: -ms-flexbox;
      width: 100%;
      color: white;
      margin-bottom: .8rem;
      cursor: pointer;

      .pic {
        flex: 1;

        img {
          width: 0.763671875rem /* pxToRem */
        ;
          height: 0.373046875rem /* pxToRem */
        ;
        }
      }

      .content::-webkit-scrollbar {
        width: 0 !important;
      }

      .desc {
        width: 1.91rem /* pxToRem */
      ;

        .top {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 0.310625rem;
        }

        &-title {
          font-size: .11rem /* pxToRem */
        ;
          line-height: 0.13rem;
          font-family: 'KABELD';
        }

        .new-info {
          display: flex;
          justify-content: space-between;

          .info-author, .info-datetime {
            font-size: 0.058rem;
            font-family: 'KABELD';
            padding-top: .11rem;

            .time {
              width: .048rem;
              height: .048rem;
              margin-right: 0.03rem;
              position: relative;
            }
          }

          @media screen and (max-width: 768px) {
            .info-datetime .time {
              width: 10px /* pxToRem */
            ;
              height: 10px;
              margin-right: 0.03rem;
              position: relative;
            }
          }

        }

        .abstract {
          margin-top: 0.3rem;
          font-size: 0.058rem;
          width: 1.4rem;
          word-wrap: break-word;
          font-family: 'KABELM';
          line-height: 1.5;
        }
      }
    }
  }


  .footer {
    margin: 0 auto;
    margin-top: .294rem /* pxToRem */
  ;
    color: #efefef;
    font-size: 0.04rem;
    text-align: center;
  }
}
</style>