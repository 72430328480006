<template>
  <nav>
    <div>
      <div class="header logo">
        <router-link :to="{ name: 'Home', query: { anchor: '' }}" @click="setAnchor('')"><img class="logo" src="../assets/logo.png" alt=""/></router-link>

      </div>
      <div class="header">
        <ul>
          <li>
            <router-link :to="{ name: 'Home', query: { anchor: 'who_we_are' }}" @click="setAnchor('who_we_are')">AboutUs</router-link>
            <router-link :to="{ name: 'Home', query: { anchor: 'clients' }}" @click="setAnchor('clients')">Portfolio</router-link>
            <router-link :to="{ name: 'Home', query: { anchor: 'community' }}" @click="setAnchor('community')">Community</router-link>
            <router-link :to="{ name: 'Blog'}">Blog</router-link>
            <a href="https://careers.arkstream.capital/">Career</a>
<!--            <router-link :to="{ name: 'Careers'}">Careers</router-link>-->
            <!--            <router-link to="/Blog" exact>Blog</router-link>-->
            <!--          <router-link to="/Careers" exact>Careers</router-link>-->
          </li>
        </ul>
      </div>
      <div class="twitter">
        <a class="twitter-a" href="https://twitter.com/ark_stream" target="_blank" rel="noopener noreferrer">
          <img class="twitter-img" :src="twitter" alt="">
        </a>
        <a class="twitter-a" href="https://arkstreamcapital.medium.com/" target="_blank" rel="noopener noreferrer">
          <img class="twitter-img" :src="medium" alt="">
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import twitter from "../assets/01.png";
import medium from "../assets/02.png";
import { onMounted, reactive, toRefs } from "vue";

export default {
  setup() {
    const data = reactive({
      twitter,
      medium,
    });
    onMounted
    return {
      ...toRefs(data),
    }
  },
  methods: {
    setAnchor(anchor) {
      localStorage.setItem("anchor", anchor)
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: none; /*消除黑点*/
  /*text-align: center;*/
  /*margin: 0;*/
}


a {
  font-size: 0.055rem;
  color: #fff; /*设置字体颜色*/
  text-decoration: none; /*消除下划线*/
  padding: 0.05rem;
  border-radius: 5px;
}

nav {
  padding-top: .15rem;
  background-color: #0c0c0c;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.logo {
  margin-left: 0.06rem;
  margin-right: .58rem;
  height: 0.12rem;
}

.header {
  float: left;
  width: .1rem;
  display: inline;
}

li {
  font-family: 'KABELM';
  display: inline-block;
  margin: .01rem .43rem;
}

.twitter {
  text-align: right;
  margin-right: .18rem;
}

.twitter-a {
  padding: .015rem;
}

.twitter-img {
  height: .08rem;
}
</style>