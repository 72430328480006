<template>
  <div id="main-content">
    <div class="banner">
      <!--      <img :src="shuxian" alt="" srcset="" class="shuxian">-->
      <img :src="title" alt="" srcset="" class="banner-title">

    </div>

    <div class="news" id="who_we_are">
      <div class="title">Who we are</div>

      <div class="content">
        <div class="thesis">
          <div class="news-item" v-for="dos of weCanDo" :key="dos.id">
            <div class="desc">
              <div class="sub">
                {{ dos.content }}
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="hengxian">-->
        <!--          <img :src="hengxian" class="hengxian">-->
        <!--        </div>-->
      </div>
    </div>

    <div class="news" id="clients">
      <p class="title">Portfolio</p>

      <div class="clients-content">
        <div class="img-box"
             v-for="client of clients"
             :key="client.id"
             @mouseover="mouseOverClient($event)"
             @mouseleave="mouseOutClient($event)"
        >
          <img
              :src="imageBaseUrl + client.logo"
              alt=""

              @click="goClient(client)"
          />
          <div class="client-name">{{ client.name }}</div>
        </div>

      </div>
    </div>

    <div class="news" id="community">
      <div class="title">Community</div>

      <div class="content">
        <div
            class="news-item"
            v-for="news of newList"
            :key="news.id"
        >

          <div class="desc">
            <div class="top">
              <div class="desc-title">
                {{ news.title }}
              </div>

              <div class="new-info">
                <div class="info-author">
                  <span class="by">BY </span>
                  <span class="author">{{ news.author }}</span>
                </div>

                <div class="info-datetime">
                  <span>
                      <img
                          :src="time"
                          alt=""
                          class="time"
                      />
                      {{ news.datetime }}
                  </span>
                </div>
              </div>
            </div>
            <div class="pic">
              <div class="arrow up-arrow" @click="changePage('add')">
                <img
                    :src="page.total > page.page * page.size ? uArrow : null"
                    alt=""
                />
              </div>
              <img :src="imageBaseUrl + news.thumbnail" alt="" class="news-img" srcset=""
                   @click="openNew(news)"/>
              <div class="arrow down-arrow" @click="changePage('min')">
                <img
                    :src="page.page > 1 ? dArrow: null"
                    alt=""
                />
              </div>

            </div>
            <div class="abstract">
              <span>
                {{ news.abstract }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="news" id="meet">
      <div class="content">
        <div
            class="team-item image"
            v-for="team of teammates.slice(0, 2)"
            :key="team.id"
            @mouseover="mouseOverTeammate($event)"
            @mouseleave="mouseOutTeammate($event)"
        >
          <img :src="imageBaseUrl + team.profile_photo" alt=""/>
          <p class="name">
            {{ team.name }}, {{ team.title }}
          </p>
          <!--          <p class="teammate-title">-->
          <!--            {{ team.title }}-->
          <!--          </p>-->
          <div class="desc-contaner">
            <p
                class="desc"
                v-for="(item, index) of team.introduction.split('\n')"
                :key="index"
            >
              {{ item }}<br>
            </p>
          </div>

          <div v-if="team.link !== ''" class="linked" @click="goLink(team)">
            <img :src="linked" alt=""/>
          </div>
        </div>

        <div class="team-item meet-team">Meet our team
          <img :src="shenglue" alt="" srcset="" class="shenglue">
        </div>


        <div class="team-item image" v-for="team of teammates.slice(2, teammates.length)" :key="team.id">
          <img :src="imageBaseUrl + team.profile_photo" alt=""/>
          <p class="name">
            {{ team.name }}, {{ team.title }}
          </p>
          <!--          <p class="teammate-title">-->
          <!--            {{ team.title }}-->
          <!--          </p>-->
          <div class="desc-contaner">
            <p
                class="desc"
                v-for="(item, index) of team.introduction.split('\n')"
                :key="index"
            >
              {{ item }}<br>
            </p>
          </div>

          <div v-if="team.link !== ''" class="linked" @click="goLink(team)">
            <img :src="linked" alt=""/>
          </div>
        </div>
      </div>
    </div>

    <div class="contact">
      <div class="us">
        <img :src="sousuo" alt="" class="sousuo"/>
        <div>
          CONTACT US
        </div>
      </div>

      <a
          class="email"
          href="mailto:official@arkstream.capital"
      >
        <img :src="email" alt=""/>
      </a
      >
    </div>

    <div class="footer">
      Copyright © 2021 ArkStream | Powered by BlockArk
    </div>
  </div>
</template>

<script>
import '../type/index.css'
import router from '@/main';
import { useRoute } from "vue-router"
import { onMounted, reactive, toRefs } from "vue";
import axios from "axios";
import linked from "../assets/Linkedin.png";
import shenglue from "../assets/shenglue.png";
import time from "../assets/time.png";
import arrow from "../assets/arrow.png";
import uArrow from "../assets/right-arrow.png";
import dArrow from "../assets/left-arrow.png";
import sousuo from "../assets/logo.png";
import twitter from "../assets/01.png";
import medium from "../assets/02.png";
import title1 from "../assets/title1.png";
import title2 from "../assets/title2.png";
import title3 from "../assets/title3.png";
import shuxian from "../assets/shuxian.png";
import hengxian from "../assets/hengxian.png";
import title from "../assets/title.png";
import email from "../assets/email.png";

const baseURL = location.hostname === "localhost" ? "/api" : "/";
const ajax = axios.create({
  baseURL,
});
export default {
  name: "App",

  setup() {
    const data = reactive({
      newList: [],
      page: {
        page: 1,
        size: 1,
        total: 0,
      },
      // imageBaseUrl: "http://150.109.75.31/",
      imageBaseUrl: location.hostname === "localhost" ? "/api" : "/",
      weCanDo: [],
      teammates: [],
      linked,
      time,
      arrow,
      uArrow,
      dArrow,
      sousuo,
      twitter,
      medium,
      shenglue,
      title1,
      title2,
      title3,
      shuxian,
      hengxian,
      title,
      email,
      clients: [],
    });

    const getNews = () => {
      ajax
          .get("/list_events/", {
            params: {
              page: data.page.page,
              pagesize: data.page.size,
            },
          })
          .then((res) => {
            if (res.data.code === 200) {
              data.newList = res.data.data.list;
              data.page.total = res.data.data.count;
            }
          });
    };

    const getTeam = () => {
      ajax.get("/list_teammates/").then((res) => {
        if (res.data.code === 200) {
          data.teammates = res.data.data;
        }
      });
    };
    const getWedo = () => {
      ajax.get("/list_what_we_do/").then((res) => {
        if (res.data.code === 200) {
          data.weCanDo = res.data.data;
        }
      });
    };

    const getClients = () => {
      ajax.get("/list_clients/").then((res) => {
        if (res.data.code === 200) {
          data.clients = res.data.data;
        }
      });
    };

    const goLink = (team) => {
      window.open(team.link);
    };

    const openNew = (news) => {
      window.open(news.link);
    };

    const goClient = (client) => {
      window.open(client.link);
    };

    const mouseOverClient = ($event) => {
      $event.currentTarget.className = "img-box mouse-over-client";
    };

    const mouseOutClient = ($event) => {
      $event.currentTarget.className = "img-box";
    };

    const mouseOverTeammate = ($event) => {
      $event.currentTarget.className = "team-item image mouse-over-teammate";
    };

    const mouseOutTeammate = ($event) => {
      $event.currentTarget.className = "team-item image";
    };

    const changePage = (type) => {
      if (type === "add" && data.page.total > data.page.page * data.page.size) {
        data.page.page++;
      } else {
        data.page.page--;
      }
      console.log(data.page.page);
      getNews();
    };
    onMounted(() => {
      getNews();
      getTeam();
      getWedo();
      getClients();
      // let anchor = localStorage.getItem("anchor")
      // console.log("onMounted", anchor)
      // if (anchor) {
      //   let el = document.getElementById(anchor)
      //   el.scrollIntoView({
      //     block: 'start',
      //     behavior: 'smooth',
      //   })
      // } else {
      //   document.documentElement.scrollTop = document.body.scrollTop = 0
      // }
    });

    return {
      ...toRefs(data),
      goLink,
      openNew,
      goClient,
      changePage,
      mouseOverClient,
      mouseOutClient,
      mouseOverTeammate,
      mouseOutTeammate
    };
  },
  mounted: function () {
    let anchor = this.$route.query.anchor
    console.log("mounted", anchor)
    if (anchor) {
      localStorage.setItem("anchor", anchor)
      let el = document.getElementById(anchor)
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    } else {
      document.documentElement.scrollTop = document.body.scrollTop = 0
    }
  },
  watch: {
    $route: {
      handler() {
        let anchor = this.$route.query.anchor
        console.log("watch", anchor)
        if (anchor) {
          localStorage.setItem("anchor", anchor)
          let el = document.getElementById(anchor)
          el.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          })
        } else {
          document.documentElement.scrollTop = document.body.scrollTop = 0
        }
      },
      // deep: true,
    }
  },
};
</script>


<style lang="scss" scoped>

#main-content {
  background-color: #0c0c0c;
  min-height: 100vh;
  max-width: 100vw;
  padding-bottom: .536rem /* pxToRem */
;


  .banner {
    padding-top: .5rem;
    margin: 0 auto;
    text-align: center;;

    .shuxian {
      width: 1.78rem;
    }

    .banner-title {
      padding-top: .6rem;
      height: .48rem;
      display: block;
      margin-left: .85rem;
    }

  }

  .font {
    font-size: .242rem;

    &-white {
      color: white;
    }

    &-black {
      color: black;
      font-size: .246rem /* pxToRem */
    ;
      -webkit-text-stroke: 1px #fff;
    }
  }

  .news {
    width: 3.04rem;
    margin: 0.5rem auto auto auto;
    padding-top: 0.3rem;
    font-size: 0.1171875rem;

    .content {
      position: relative;

      .arrow {
        cursor: pointer;
        height: 1.7rem;
        width: 7%;
        z-index: 100;

        img {
          margin: .75rem .05rem;
          width: .15rem;
        }
      }

      //.arrow:hover {
      //  background-color: gray;
      //}

      .down-arrow {
        float: left;
      }

      .up-arrow {
        float: right;
      }
    }


    .title {
      font-family: 'KABELM';
      font-size: .12rem;
      float: right;
      height: .12rem;
      line-height: .2rem;
      color: #1f47b3;
      margin-bottom: 0.35rem;
      margin-left: 2rem;
    }

    .news-item {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      display: -moz-flex;
      display: -ms-flexbox;
      width: 100%;
      color: white;
      margin-bottom: .05rem /* pxToRem */
    ;

      .pic {
        flex: 1;
        cursor: pointer;
        margin-top: .15rem;
        margin-left: -.25rem;

        .news-img {
          width: 86%;
          height: 1.7rem;
        }
      }

      .content::-webkit-scrollbar {
        width: 0 !important;
      }

      .desc {
        margin-left: .03rem;

        .top {
          display: flex;
          flex-direction: column;
          width: 1.91rem;
          justify-content: space-between;
          height: 0.310625rem;
          margin-bottom: .28rem;
        }

        &-title {
          font-size: .12rem;
          line-height: 0.14rem;
          font-family: 'KABELM';
          margin-bottom: .06rem;
        }

        .new-info {
          display: flex;
          justify-content: space-between;

          .info-author, .info-datetime {
            font-size: 0.058rem;
            font-family: 'KABELM';

            .time {
              width: .048rem;
              height: .048rem;
              margin-right: 0.03rem;
              position: relative;
            }
          }

          //@media screen and (max-width: 768px) {
          //  .info-datetime .time {
          //    width: 10px /* pxToRem */
          //  ;
          //    height: 10px;
          //    margin-right: 0.03rem;
          //    position: relative;
          //  }
          //}
        }

        .abstract {
          margin-top: 0.155rem;
          font-size: 0.06rem;
          word-wrap: break-word;
          font-family: 'KABELM';
          line-height: 2.1;
        }
      }
    }
  }

  #who_we_are {
    height: 2rem;

    .thesis {
      float: left;
      width: 2.1rem;
      margin-right: .2rem;
      margin-bottom: 1rem;

    }

    .hengxian {
      float: right;
      width: .77rem;
    }

    .sub {
      font-size: .06rem;
      line-height: .15rem;
      font-family: 'KABELM';
    }
  }

  #meet {

    .content {
      display: flex;
      flex-wrap: wrap;

      .team-item {
        width: 0.900390625rem;
        background-color: #1e1e1e;
        height: 1.35rem;
        position: relative;
        overflow: hidden;
        margin: 0 .055rem;
        margin-bottom: .48rem;
      }

      //.desc-contaner:hover {
      //  transition: all 1s;
      //  color: white;
      //  margin-top: -0.6rem;
      //  margin-left: auto;
      //  margin-right: auto;
      //  height: 90%;
      //  overflow-y: auto;
      //  background-color: gray;
      //  opacity: 0.95;
      //}

      .desc-contaner {
        width: 90%;
        height: 35%;
        overflow-y: hidden;
        display: inline-block;
        font-family: 'ArialBlack';
        word-break: break-word;
        -webkit-transition-property: background-color;
        -webkit-transition-duration: 0.3s;
        -webkit-transition-timing-function: ease;
      }

      @media screen and (max-width: 1.5rem) {
        .team-item {
          width: 100%;
          background-color: #141414;
          border-radius: 0.044rem;
          position: relative;

          padding-bottom: 0.04rem;
          margin-bottom: 0.04rem;
          overflow: hidden;

          &:nth-child(1) {
            height: 50px;
          }

          .shenglue {
            display: none;
          }

          &:nth-child(1n) {
            margin: 0;
            margin-bottom: 20px;
            padding-bottom: 50px;
            height: auto;
          }

        }
      }

      .meet-team {
        font-family: 'KABELM';
        background: transparent;
        font-size: 0.113rem;
        color: #1f47b3;
        margin-bottom: 0.04rem;
        position: relative;
        text-align: right;

        .shenglue {
          position: absolute;
          height: .122rem;
          width: .034rem;
          bottom: 10px;
          right: 0;
        }
      }


      .image {
        text-align: center;
        color: #fff;

        img {
          margin-top: 0.08rem;
          width: .4rem;
          height: .4rem;
          border-radius: 50%;
        }

        p.name {
          margin-top: .02rem;
          margin-left: .05rem;
          margin-bottom: .03rem;
          font-size: .04rem;
          text-align: left;
          font-family: 'KABELM';
        }

        //p.teammate-title {
        //  margin: .02rem auto;
        //  font-size: .04rem;
        //  font-family: 'KABELM';
        //}


        @media screen and (max-width: 768px) {
          div.desc-contaner {
            width: 80%
          }
        }

        p.desc {
          font-family: 'KABELM';
          text-align: left;
          font-size: 0.03rem;
          line-height: 2;
        }
      }
    }

    .linked {
      text-align: center;
      margin-top: -.05rem;;
      left: 50%;
      transform: translateX(-50%);

      img {
        margin-left: .8rem;
        cursor: pointer;
        width: 0.16rem !important;
        height: 0.16rem !important;
      }
    }
  }

  #clients {
    width: 100%;
    text-align: center;

    .title {
      margin-left: .5rem;
      margin-right: .35rem;
    }

    .clients-content {
      width: 2.76rem /* pxToRem */
    ;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      margin-top: 0.23046875rem /* pxToRem */
    ;

      .img-box {
        width: 14%;
        height: .524rem /* pxToRem */
      ;

      }

      .mouse-over-client {
        transition: all .7s;
        background-color: #222222;

        .client-name {

          transition: all 1s;
          color: white;
        }
      }

      .client-name {
        color: #0c0c0c;
        font-size: 0.05rem;
        font-family: 'ArialBlack';
        margin-top: -0.1rem;
      }

      img {
        width: .4rem /* pxToRem */
      ;
        height: .4rem /* pxToRem */
      ;
        cursor: pointer;
      }
    }
  }

  .contact {
    margin-left: .39rem;
    margin-top: 0.8rem;
    color: #fff;
    font-weight: 900;
    font-size: 0.05rem;

    .us {
      font-family: 'ArialBlack';
      width: 0.76rem;
      font-size: 0.15rem;
      margin-top: .224rem /* pxToRem */
    ;

      .sousuo {
        width: .41rem;
        margin-bottom: 0.1953125rem;
      }
    }

    a {
      text-decoration: none;
      padding: 0.02rem 0.04rem;
      height: 0.08rem;
      line-height: 0.08rem;
    }

    .email {
      display: flex;
      margin-top: 0.17rem;
      align-items: center;

      img {
        width: .65rem;
      }
    }
  }

  .twitter {
    margin: 0 auto;
    margin-top: .23rem;
    margin-right: 0.33203125rem;
    text-align: right;

    img {
      width: 0.2rem;
      height: 0.2rem;
      margin-right: .06rem /* pxToRem */
    }
  }

  .footer {
    margin-top: .7rem;
    color: #efefef;
    font-size: 0.04rem;
    text-align: center;
  }
}
</style>