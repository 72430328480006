<template>
  <div id="app">
    <pageheader></pageheader>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from './components/Header'
import Home from './components/Home'
import Blog from './components/Blog'
import Careers from './components/Careers'

export default {
  name: 'App',
  components:{
    "pageheader":Header,
    "Home":Home,
    "Blog":Blog,
    "Careers":Careers,
  },

}
</script>

<style>
#main-content {
  background-color: #0c0c0c;
  min-height: 100vh;
  max-width: 100vw;
  padding-bottom: .536rem /* pxToRem */;
}
</style>