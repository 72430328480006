<template>
  <div id="main-content">

    <div class="banner">
      <img :src="career" alt="" srcset="" class="career career-img">
      <img :src="career_desc" alt="" srcset="" class="career_desc career-img">
      <img :src="career_lines" alt="" srcset="" class="career_lines career-img">
    </div>
    <iframe class="iframe" src="https://careers.arkstream.capital/"></iframe>

    <div class="footer">
      Copyright © 2021 ArkStream | Powered by BlockArk.
    </div>
  </div>
</template>

<script>
import '../type/index.css'
import { onMounted, reactive, toRefs } from "vue";
import axios from "axios";
import linked from "../assets/Linkedin.png";
import shenglue from "../assets/shenglue.png";
import time from "../assets/time.png";
import arrow from "../assets/arrow.png";
import uArrow from "../assets/right-arrow.png";
import dArrow from "../assets/left-arrow.png";
import sousuo from "../assets/logo.png";
import twitter from "../assets/01.png";
import medium from "../assets/02.png";
import career from "../assets/career.png";
import career_desc from "../assets/career_desc.png";
import career_lines from "../assets/career_lines.png";

const baseURL = location.hostname === "localhost" ? "/api" : "/";
const ajax = axios.create({
  baseURL,
});
export default {
  name: "App",

  setup() {
    const data = reactive({
      newList: [],
      page: {
        page: 1,
        size: 2,
        total: 0,
      },
      // imageBaseUrl: "http://150.109.75.31/",
      imageBaseUrl: location.hostname === "localhost" ? "/api" : "/",
      weCanDo: [],
      teammates: [],
      linked,
      time,
      arrow,
      uArrow,
      dArrow,
      sousuo,
      twitter,
      medium,
      shenglue,
      career,
      career_desc,
      career_lines,
      clients: [],
    });

    const getNews = () => {
      ajax
          .get("/list_news/", {
            params: {
              page: data.page.page,
              pagesize: data.page.size,
            },
          })
          .then((res) => {
            if (res.data.code === 200) {
              data.newList = res.data.data.list;
              data.page.total = res.data.data.count;
            }
          });
    };

    const getTeam = () => {
      ajax.get("/list_teammates/").then((res) => {
        if (res.data.code === 200) {
          data.teammates = res.data.data;
        }
      });
    };
    const getWedo = () => {
      ajax.get("/list_what_we_do/").then((res) => {
        if (res.data.code === 200) {
          data.weCanDo = res.data.data;
        }
      });
    };

    const getClients = () => {
      ajax.get("/list_clients/").then((res) => {
        if (res.data.code === 200) {
          data.clients = res.data.data;
        }
      });
    };

    const goLink = (team) => {
      window.open(team.link);
    };

    const openNew = (news) => {
      window.open(news.link);
    };

    const goClient = (client) => {
      window.open(client.link);
    };

    const mouseOverClient = ($event) => {
      $event.currentTarget.className = "img-box mouse-over-client";
    };

    const mouseOutClient = ($event) => {
      $event.currentTarget.className = "img-box";
    };

    const changePage = (type) => {
      if (type === "add" && data.page.total > data.page.page * data.page.size) {
        data.page.page++;
      } else {
        data.page.page--;
      }
      console.log(data.page.page);
      getNews();
    };
    onMounted(() => {
      getNews();
      getTeam();
      getWedo();
      getClients();
    });

    return {
      ...toRefs(data),
      goLink,
      openNew,
      goClient,
      changePage,
      mouseOverClient,
      mouseOutClient,
    };
  },
};
</script>


<style lang="scss" scoped>

#main-content {
  background-color: #0c0c0c;
  min-height: 100vh;
  max-width: 100vw;
  padding-bottom: .536rem /* pxToRem */
;


  .banner {
    font-family: 'avgr65w';
    margin: 0 auto;
    text-align: center;;
    .career-img {
      display: block;
      margin: 0 auto;
      margin-bottom: .1rem;
    }
    .career {
      width: .45rem;
    }
    .career_desc {
      width: 1.05rem;
    }
    .career_lines {
      width: 1.8rem;
    }
  }

 .iframe {
   width: 80%;
   height: 2rem;
   margin: 0 .4rem;
 }

  .footer {
    margin: 0 auto;
    margin-top: .294rem /* pxToRem */
  ;
    color: #efefef;
    font-size: 0.04rem;
    text-align: center;
  }
}
</style>