import { createApp } from 'vue'
import App from './App.vue'

import { createRouter } from "vue-router"
import { createWebHashHistory } from "vue-router"

const Careers = () => import("./components/Careers")
const Blog = () => import("./components/Blog")
const Home = () => import("./components/Home")

const routes = [
    {
        path: '/',
        redirect: 'Home'
    },
    {
        path: "/Home",
        name: "Home",
        component: Home
    },
    {
        path: "/Careers",
        name: "Careers",
        component: Careers
    },
    {
        path: "/Blog",
        name: "Blog",
        component: Blog
    }
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
})
const app = createApp(App);
app.use(router)
app.mount('#app')
